:root {
  --messenger-blue: #0078ff;
  --sms-green: #53d769;
  --light-gray: #001b480f;
}

body {
  --bg-gray: #f9fbfd;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-gray {
  background-color: var(--bg-gray);
}

.glow-underline {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.glow-underline:focus {
  outline: none !important;
  border-bottom: 1px solid var(--bs-primary) !important;
  box-shadow: 0 4px 5px -5px var(--bs-primary);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.text-indigo {
  color: var(--bs-indigo);
}

.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 18px !important;
}

.bg-black {
  background-color: black !important;
}

.text-messenger-blue {
  color: var(--messenger-blue) !important;
}

.bg-messenger-blue {
  color: var(--messenger-blue) !important;
}

.border-messenger-blue {
  border-color: var(--messenger-blue) !important;
}

.text-sms-green {
  color: var(--sms-green);
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.flex-basis-20 {
  flex-basis: 20%;
}

.link-disabled {
  pointer-events: none;
  opacity: 50%;
}

.line-break-anywhere {
  line-break: anywhere;
}

@media (min-width: 767.98px) {
  .flex-lg-1 {
    flex: 1;
  }
}

.blur-3 {
  filter: blur(3px);
  pointer-events: none;
}

.blur-9 {
  filter: blur(9px);
  pointer-events: none;
}

.blur-10 {
  filter: blur(10px);
  pointer-events: none;
}

@media (min-width: 767.98px) {
  .vh-md-100 {
    height: 100vh;
  }
}

